.nav {
  position: relative;
  /* left: 0; */
  right:0;
  /* border:1px solid red; */
  height:50px;
}
.menu {
  display: flex;
  flex-direction: column;
}

.logInfo {
  /* border:1px solid red; */
 
  /* font-size: 1.2em; */
/* padding-top: 30px; */
  height: 24px;
  
}
.email {
  position: absolute;
  top:10px;
  padding-right: 10px;
 /* margin-bottom: -10px !important; */
  /* font-size: 1em; */
  border:1px solid red
}
.email::first-letter {
  text-transform: capitalize;
}
.logud {
  background: transparent;
  outline: none;
  border: none;
  color: inherit;
  font-size: 1em;

  border-left: 2px solid darkgrey;
}

.bm-item {
  /* border:1px solid white; */

  text-decoration: none;
  margin-bottom: 10px;
  /* color: white; */
  transition: color 0.2s;
}



.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 0;
  /* left: 36px; */
  /* top: 36px; */
}

.bm-burger-bars {
  /* border: 1px solid white; */
  background-color:white;
  /* color: red; */
  /* border: 1px solid red; */
}
.bm-burger-bars span{background-color: black;}

.bm-cross-button {
  height: 24px;
  width: 24px;
}



.bm-menu {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  /* padding:50px; */
  /* height: 50vh; */

  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}


.bm-menu-wrap {
  position: absolute;
  /* display: flex; */
  /* flex-direction: column; */
  height: 50vh !important;
  top: 93px;
  /* bottom: -500px; */
  /* border: 1px solid rgb(172, 37, 37); */
  background-color:darkgray;
  color: black;
}
.menu-item {
  /* color: white; */
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}
.dd {
  /* display: flex; */
  flex-direction: column;
}
.menu-item:hover {
  text-decoration: none;
  color: inherit;
  background-color: #373a47;
}
