
.films_sum {
    border:1px solid  #dee0e3;
    /* padding: 14px 59px 10px 0; */
    display:grid;
    grid-template-columns: 1fr 1fr;
    height:50px;
    padding:10px;
    position: relative;
    margin-top: -10px;
    color:#46484c
    /* font-size: 30px; */
}

.films_data{
    display:flex;
    padding-top:2px;
    margin-right: 50px;
    background-color:#f7f7f7 ;
    margin-left: 10px;
   
    padding-right:10px;
    height:28px;
    
}

.films_line {
 width:1px;
 height:48px;
 background-color: #dee0e3;
 position: absolute;
 right:50px;
 top:0;

}

.film_line button{
    border:none !important
}
.films_arrows{
    position: absolute;
    right:10px;
    top:8px;
    height:10px;
    cursor:pointer;
    border:none;
    background: transparent;
    /* transform: rotate(-180deg); */
}
.rotated {
    transform: rotate(-180deg);
}
.table_data {
    /* border-right:1px solid #dee0e3; */
    width:100%;
    padding:8px 60px 8px 8px;
}

.formatted_table td {
    border-style: none;
    padding:0;
    margin:0
} 
.table:not(caption){
    padding:0
}
.films_summary {
    border-right:1px solid #dee0e3;
    border-left:1px solid #dee0e3;
    border-bottom:1px solid #dee0e3;

    padding-top:8px;
    padding-left:8px;
    padding-bottom:8px;
    padding-right:60px;
}

.formiddag_cont {
    display:flex;
    justify-content: space-between;
    padding-right: 10px; 
    background-color: rgba(169, 229, 255, .3);
    
}
.day_table td button {
    border:none
}
.day_table td{
    font-size:15px
 }
 .tbody_space tr{
   padding:0px 12px
 }
 .tbody_space:before {
    content: "-";
    display: block;
    line-height: 8px;
    color:transparent;
    
}
.select {
    height:40px;
    width:293px;
    border-radius: 4px;
    background-size: 19px 19px;
    background-position: right 0.5rem center;

    /* background-position: 0.75rem center; */
}
/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */ 
    /* background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
    /* background: url("http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png") white no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */
  
/* select {
    appearance: none;
    background: white;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16px' height='16px' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M6.1018 8C5.02785 8 4.45387 9.2649 5.16108 10.0731L10.6829 16.3838C11.3801 17.1806 12.6197 17.1806 13.3169 16.3838L18.8388 10.0731C19.5459 9.2649 18.972 8 17.898 8H6.1018Z' fill='%23212121'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    border-radius: 2px;
    padding: 0.25rem 2rem 0.25rem 0.75rem;
  } */
.select {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="#fff" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>') right 1rem center/8px 10px no-repeat;
}
.films_info{
    display:flex;
    /* margin-left: 10px; */
    padding-right:10px;
    height:28px; 
}
.firstEftermiddagContainer{
   background-color:  rgba(255, 155, 47, .3);
   display:grid;
   grid-template-columns: .4fr .9fr 1fr;
   color:#46484c;
   /* width:93%; */
   height:25px;
   /* margin-top:8px; */
   /* margin-left:8px */
}


.firstefter_cont {
    display:flex;
    justify-content: space-between;
    padding-right: 10px;
    background-color:  rgba(255, 155, 47, .3);
}
.andenefter_cont{
    background-color: rgba(219, 231, 84, .3);
}
.firstaften_cont {
    background-color: 
    rgba(173, 176, 255, .3);
}
.andenaften_cont {
    background-color:  rgba(126, 229, 109, .3)
}
.sum_film_head{
    display:grid;
    grid-template-columns: 1fr 1fr ;
}
.summ_film_head{
    display:grid;
    grid-template-columns: 1fr 1fr ;
}
/* .firstEftermiddag{
    background-color: rgba(255,155,47,.3) ;
    position: relative;
    top:0;
} */
/* .andenaften_cont{
    background-color: rgb(126, 229, 109);
} */
.timestamp {
    position: absolute;
    top:100px
}
/* .burgermenu .films_info {
    width:420px
} */
.burgermenu  .sum_film_titles {
    /* width:595px; */
    margin-right:30px;
    /* border:1px solid red */
}
@media screen and  (width:1180px){
    /* .burgermenu .films_data{
        width:537px
    } */
    .burgermenu  .sum_film_titles {
        width:595px;
        margin-right:25px;
    }
     
}
@media screen and (min-width:900px) {
.films_info{
    /* width:537px */
}
}
@media screen and  (min-width:1025px) {
    .burgermenu   .summ_film_head{
        grid-template-columns: 1fr 1fr;
    }
    /* .burgermenu .films_info {
        width:537px
    } */
    .burgermenu  .sum_film_titles {
        width:580px;
        /* margin-right:45px; */
    }
}
@media screen and  (max-width:1024px) {
    .firstEftermiddagContainer{
        grid-template-columns: .7fr .9fr 1fr;
    }
    .sum_film_head{
        grid-template-columns: 1fr 1.5fr;
    }
   
}

@media screen and  (max-width:1200px) {
    .table-responsive{
        font-size: 13px;
    }
    .dagsperioder {
        font-size:13px
    }
    .sum_film_titles {
        width:440px
    }
}

@media print {
    .dagsperioder {
        margin:0;
        width:100vw
    }
    .films_summary {
        margin:0;
        width:100vw;
    }
    .billetr {
        /* background-color: red; */
        width:100px;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}