.contain {
    /* padding:10px; */
    /* padding-top: 30px; */
    /* width:80%; */
    /* margin:auto; */
    /* min-height:90vh; */
   /* border:1px solid red */
  }
.dagensTitle{
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
}
.dagensHeader {
  display:flex;
  justify-content: space-between;
}
.dagensdata {
  border:1x solid  #0e5acc;
}
  .dagens-menu{
    display:flex;
    border:1px solid red;
    /* flex-direction: row; */
    justify-content: space-between;
  }
.entity {
 cursor: pointer;
}

.dagensrapport {
  position: relative;
  /* border:1px solid green; */

}
.biografBox{
  /* border:1px solid black; */
  display:flex;
  /* flex-direction: column; */
}
.dates-container{
  width:300px;
  /* border:1px solid lightgrey; */
display:flex;

}
.dagenTable tbody td {
  padding:15px
}
.dates {
  font-size: 18px;
  border:1px solid lightgrey;
  width:400px;
  margin:auto;
  text-align: center;
  background-color: var(----primary-color);
  color:white;
  border-radius: 5px;
  /* height:50px; */
  /* display:grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  /* display:flex; */
  position: relative;
  right:0;
  /* box-sizing: border-box; */
  /* justify-content: space-between; */
  padding: 5px ;
  /* padding-top: 10px; */
}


.arrow-container-back {
  /* border:1px solid grey; */
  position: absolute;
  top:5px;
  left:5px;
  border-radius: 5px;
background-color: var( ----navmenu-background);
/* color:white; */
/* color:black; */
cursor: pointer;
/* width:40px; */

padding:0px 3px;

}
.arrow-container-for {
  /* border:1px solid grey; */
  position: absolute;
  top:5px;
  right:5px;
  border-radius: 5px;
background-color: var(----navmenu-background);
/* color:white; */
/* color:black; */
cursor: pointer;
padding:0px 3px;

}
.backwards , .forwards {
  /* border:1px solid grey; */
  font-size: 10px;
  /* position: absolute; */
  /* left:0; */
  /* line-height: 15px; */
  /* padding-top:10px; */
  /* width:50px; */
  /* padding-top: -20px; */
  
}
.countdown_container{
  position: absolute;
  right:0;
  top:-90%;
  text-align: center;
}

.calendar-container {
  /* border:1px solid red; */
  width:260px;
  display:flex;
  justify-content: space-between;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ; */
  padding:5px 0px;
  position:relative;
  right:0;
  height:22px;
  top:38px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 4px;
  /* margin:aut'o; */
}
.text {
  font-size: 10px;
}

.Calendar {
  position: absolute;
  /* padding:10px; */
  right: 0px;
  top:40px;
  letter-spacing: .3px;
  z-index: 100;
  border:1px solid lightgrey;
  width:450px;
  /* background-color: white; */
  padding-left:-10px;
  box-shadow: 0 13px 13px 0 rgb(0 0 0 / 12%);
  
}

.calendar-icon {
  height:19px;
  margin-top:-4px;
 /* margin-left: -50px; */
 /* position: absolute; */
  /* border:1px solid white; */
  /* background-color: white; */
  
}
/* .myTable  tbody td {
  padding:15px;
  color:#46484c
} */
.left-arrow {
  transform: rotate(90deg);
  cursor: pointer;
  /* height:13px; */
}
.left-arrow:hover {
  border:1px solid white
}
.select_box {
  position: relative;
}
.select_box:after{
  /* background-color: #0e5acc; */
  width: 0; 
  height: 0; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #f00;
  position: absolute;
  top: 40%;
  right: 5px;
  content: "";
  z-index: 98;
  
}
.dagens_body > tr:last-of-type {
  border-bottom: none
}

.right-arrow {
  transform: rotate(-90deg);
  /* margin-top: 6px; */
  /* margin-right: -20px; */
  cursor: pointer;
  /* height:13px; */
  /* border:1px solid white; */
 /* background-color:white; */
}
.arrows {
  /* margin-top: -2px; */
}
.weekdays {
 
  margin-bottom: -20px;
  margin-left: 45px;
  font-weight: bold;
  display:flex;
  justify-content: space-around;
  position: absolute;
  top:135px;
  left:10px;
  font-size: 16px;
  width:360px;
  /* border:1px solid red */
  /* color:#46484c; */
}

.days {
  letter-spacing: 0.4px;
  font-weight: bold;
  width: var(--rdp-cell-size) !important;
  height: var(--rdp-cell-size) !important
}

@media screen and (max-width:1317px) {
  .dagens-menu {
    flex-direction: column;
  }
  .countdown_container{
    top:0;
  }
 .calendar-container {
  margin-bottom: 50px;

 }
 
  .Calendar {
    /* border:1px solid red; */
    top:-120px
  }
}
@media screen and (max-width:900px){
  .dagens-menu {
    flex-direction: column;
  }
  .calendar-container {
    /* width:375px; */
    padding:0;
    right:10px
  }
  .calendar-icon {
    /* border:1px solid red; */
    margin-left:0;
    /* margin-top:3px */
  }
}

@media screen and (max-width:500px) {
.calendar-container {
  /* width:100vw; */
}
.weekdays {
  margin-left: 40px;
    /* border:1px solid red; */
    width:330px;
    left:3px;

}
}
@media screen and (max-width:390px) {
  .weekdays {
    width:280px;
    margin-left:50px
  }
}