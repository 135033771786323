.workplanHeader{
/* border: 1px solid red; */
margin-top: 10px;
display:grid;
grid-template-columns: 1fr 0.3fr 0.3fr 0.28fr 0.19fr;
/* padding-left: 28px; */
height:31px;
margin-top:27px
}

.workplanBody {
display:grid;
grid-template-columns: 1fr 0.3fr 0.3fr 0.28fr 0.2fr;
height: 55px;
padding-left:21px;
padding-top:8px;
position: relative;
/* background-color:antiquewhite ; */
}
.select {
    border: 1px solid #ccc;
    height: 34px;
    width: 250px;
    padding: 6px 12px;
    line-height: 1.42857143;
  }
 select {
background-size: 16px 19px;
  }
   /* .custom-select {
    background:  url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2719%27 height=%2719%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27red%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") no-repeat right .75rem center/15px 20px !important;
  }  */
  .selectwrap {
    position: relative;
    float: left;
  }
  
  .selectwrap:after {
    content: "";
    position: absolute;
    width: 0; 
    height: 0; 
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;  
    border-top: 8px solid #000;
    right: 4px;
    top: 14px;
    pointer-events: none;
  }
/* .form-select-bg-size:       {
    16px 12px; 
}        */
/* .workplanBody:nth-child(odd) {
    background-color:  #f7f7f7;
    border-top:1px solid #dee0e3;
    border-bottom: 1px solid #dee0e3
} */
.indluk {
    border:1px solid green;
    width: 64px;
    height: 26px;
    margin-left:-20px;
    margin-top: 5px;
    /* padding-bottom: 20px; */
    box-sizing: border-box;
    padding-left: 10px;
    /* padding-top:-40px; */
    border-radius: 40px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #40b22d;
    color:white;
}

.noTime {
    padding-left:15px;
    width:30px;
    padding-top: 5px;
    /* margin-top: 8px; */
}

.udluk {
    
    width: 64px;
    height: 26px;
    padding-left: 10px;
    margin-top: 5px;
    margin-left: -15px;
    box-sizing: border-box;
    border-radius: 40px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #cf4747;
    color:white;
}

.workplanBody p {
    /* border:1px solid red */
}

@media screen and (max-width:1100px){
    .trunc {
        max-width:250px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}