.rapport {
  
  margin-top: 52px;
  margin-left: 104px;
  margin-right: 96px;
  
}
.periodAndWeeksContainer {
  /* border:1px solid red; */
  display:grid;
  grid-template-columns: 2fr 0fr;
}
.weekRadioBtns {
  width: 262px;
  height: 110px;
  /* margin: 0px 0 0 15px; */
  padding: 10px 46px 13px 12px;
  border-radius: 4px;
  /* background-color: #f7f7f7; */
}
.divider{
  width: 1px;
  height: 40px;
  flex-grow: 0;
  margin-right: 12px;
  
  background-color: #d9d9d9;
}
.title {
  text-align: center;
}
hr.newhr {
  border: 1px solid white;
}

nav {
  display: flex;
  justify-content: space-between;
}
.button_2 {
  position: absolute;
  top: 0px;
  right:23px;
  width:161px;
}
 form .form-select {
  max-width: 400px;
  width: 310px;
  height: 40px;
  /* color: #46484c; */
  background-size: 16px 19px;
  padding: 0.375rem 3rem 0.375rem 0.75rem;
  /* margin-top: -5px; */
}
form .bio_box {
  width:376px
}
.titleContainer{
  /* border:1px solid red; */
  display:flex;
  justify-content: space-between;
}
.countdownDiv {
  /* border:1px solid black; */
  width:53px;
  position: relative;
}
.secondCounter{
  /* border:1px solid red; */
  position: absolute;
  /* left:12px; */
  top:12px;
  right:-12px;
  width:58px;
}
.timeRemained {
position: absolute;
left:6px;
top:0px;
right:0px;
width: 45px;
}
.seconds {
  position: absolute;
  top:0;
  left:30px;
}
.custom-select-line {
  /* border-left:1px solid red; */
  width: 1px;
  position: absolute;
  top: 38px;
  right: 20px;
  height: 39px;
  margin: 0 11px 0 46px;
  background-color: #8d8d8d;
}
.custom-select-lineD {
  /* border-left:1px solid red; */
  width: 1px;
  position: absolute;
  top: 41px;
  right: 40px;
  height: 40px;
  margin: 0 11px 0 46px;
  background-color: #8d8d8d;
}

  .btnsgroup {
    display:flex;
    justify-content: space-between;
    padding-right:10px
  }
  .btnsgroup > .butto{
    font-size: 14px !important;
    /* padding:5px 24px !important; */
  }
   .button_0 , .button_1 {
    width: 119px 
  }
   .button_3 ,.button_4 , .button_5, .button_6 {
    width:97px;
  }
 
.biograf {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  padding-right: 15px;
 
}
.period-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
}
.tableData{
  margin-top: 15px;
}
.countdownContiner {
  display:flex;
  justify-content:end;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-style: italic;
  /* color:#800409; */
  font-weight: 500;
  /* border:1px solid; */
  position: relative;
}
.select-line{
  position: absolute;
  right:0;
  width:1px;
  right: 30px;
    height: 40px;
    margin: 0 11px 0 46px;
    /* background-color: #dee0e3; */
}
.filmDate {
  /* border:1px solid red; */
  display: flex;
  justify-content: space-between;
  height:80px;
  
}
.periodAndShow {
  /* display:flex */
}

.biografBox {
  /* border:1px solid red; */
  display: flex;
  width: 300px;
  position: relative;
  flex-direction: column;
}
.movieListBox{
  /* width:376px; */
  display:flex;
  /* justify-content: flex-end; */
  position: relative;
}
.movieListBox .custom-select-line {
  top:0 
}
.movie {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.36px;
  margin-top: 7px;
  margin-right: 16px;
  /* padding-right: 15px; */
}
.date-box {
  width: 180px;
  height: 40px;
  margin: 0px 14px 0px 9px;
  padding-top: 6px;
  padding-left: 13px;
  border-radius: 4px;
  border: solid 1px #dee0e3;
  position: relative;
  /* display:flex; */
}
.date-box-til {
    width: 211px;
  height: 40px;
  margin: 0px 20px 0px 6px;
  padding-top: 6px;
  padding-left: 13px;
  border-radius: 4px;
  border: solid 1px #dee0e3;
  position: relative;
}
.date-box-calendar {
  position: absolute;
  right: 10px;
  top:8px

  /* background-color: white; */

}
.date-box-til-calendar {
    position: absolute;
  right: 10px;
  top:8px
  /* background-color: white; */

}
.date-box-line {
  width: 1px;
  position: absolute;
  right: 40px;
  /* background-color: #dee0e3; */
  height: 38px;
  top: 0;
}
.date-box-til-line {
    width: 1px;
  position: absolute;
  right: 40px;
  /* background-color: #dee0e3; */
  height: 38px;
  top: 0;
}
.first-date {
  width: 98px;
  height: 20px;
  /* margin: 0px 29px 0px 0; */
  font-family: Lato;
  /* padding-top:8px; */
  text-transform: lowercase;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.25; */
  letter-spacing: 0.32px;
  /* text-align: left; */
  /* color: #46484c; */
}
select {
    width:293px;

}
option {
    width:293px;

}
.fra {
  font-weight: bold;
  letter-spacing: 0.36px;
  /* color: #46484c; */
  margin-top: 8px;
}
.hentBtn {
  width: 122px;
  height: 40px;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  /* font-stretch: normal; */
  /* font-style: normal; */
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  color: #fff;
  /* padding: 12px; */
  margin-top: 41px;
  /* margin: 11px 0 60px 50px; */
}
@media  screen and (min-width:900px) {
  .btnsgroup{
    width:704px
  }
  
}
@media screen and (max-width: 1317px) {
  .rapport {
    /* width: 100vw; */
    min-height: 100vh;
    /* margin-top: 0px; */
    margin-left: 10px;
    margin-right: 10px;
    /* padding:10px */
    /* background-color: aqua; */
    /* font-size: 12px; */
  }
 
  .period-box {
    margin-top: 10px;
  }
  .filmDate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:auto
    /* border:1px solid red; */
    /* height:200px; */
    /* margin-bottom: 70px; */
  }
  .hentBtn {
    /* margin-top: 10px; */
    /* margin-bottom: -10px; */
    /* color:red; */
  }
  .hentBtnCon{
    margin-top: 10px;
  }
  .btn {
    /* border:1px solid red; */

    margin-top: -50px;
  }
  .biografBox {
    flex-direction: column;
  }
  table {
    /* font-size: 12px; */
  }
}
@media screen and (max-width:499px) {
  .custom-select-line{
    right:30px
  }
  .tableData{
    margin-top: 360px;
  }
  .button_2{
    left:10px;
    top:185px;
    width:204px
  }
  .btnsgroup{
    margin-top: 70px;
  }
 
}
@media screen and (max-width:700px) and (min-width:500px) {
  .btnsgroup{
    margin-top: 50px;
  }
  .button_2 {
    left:35px;
    top:70px
  }
  .tableData{
    margin-top: 100px;
  }
  .select-line{
    display:none
  }
}
@media screen and (max-width:750px) and (min-width:600px){
  
}
/* @media screen and (max-width:1055px) and (min-width:900px){
  .button_7{
    right:5%
  }
} */
@media screen and (max-width: 900px) {
  .rapport {
    margin-left: 0px;
    margin-right:0px;
    margin-top:52px;
    /* width:700px */
  }
  .custom-select-line {
    right:40px
  }
form .form-select {
  width:288px
}
  .timeAndMovies{
    flex-direction: column;
  }
  .biograf_box{
    flex-direction: column;
  }
  .periodAndWeeksContainer{
    display:block
  }
.countdownContiner{
  right:10px
}
.progressbarContainer{
  margin-right: 10px;
}
.btnsgroup {
  flex-wrap: wrap;
  justify-content: stretch;
}
  .hentBtnCon{
    margin-top: -10px;
    margin-left: 10px;

  }
  .hentBtn {
    width:180px
  }

}
@media screen and (max-width:1030px) and (min-width:700px){
  .button_2{
    top:47px
  }
  .tableData {
    margin-top: 170px;
  }
  .select-line {
    left:360px;
  }
}

@media screen and (max-width:1030px) {
  .periodAndWeeksContainer{
    grid-template-columns: 1fr;
  }
  
  
}

@media print {
  .rapport {
    margin:10px
  }
  .updateTimer{
    display:none
  }
  .filmDate {
    display:none
  }
}