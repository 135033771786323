
/* REACT DAY PICKER STYLING starts here*/

.rdp-caption {
  display:inherit;
  text-align: center  !important;
  margin-bottom: 80px !important;

}

.rdp-caption_label{
  border:none;
  font-size: 16px;
  /* color:#3d3f43; */
}
.rdp-caption_label svg {
  height:22px !important
}
.rdp-dropdown_month {
  border-bottom: 1px solid 
  #e7e9e9 ;
  text-transform: capitalize;

}
.rdp-dropdown_year{
  border-bottom: 1px solid 
  #e7e9e9 ;
  margin-left: 20px;
}
.rdp-dropdown {
  text-transform: capitalize;
  padding:10px;
  /* margin-bottom: 40px !important; */

}

.dayPicker {
  border:1px solid lightgrey;
    width:450px;
    margin-top: 50px;

    /* background-color: white; */
    padding-left:-10px;
    box-shadow: 0 13px 13px 0 rgb(0 0 0 / 12%);
}
/* REACT DAY PICKER STYLING ends here*/

.home-container {
  /* height: 99.8vh; */
  display: grid;
  grid-template-rows: 70px auto;
}

.main {
  /* border:1px solid red; */
  /* height:-webkit-fill-available; */
}

.home {
  display: grid;
  grid-template-columns: 18% 82%;
  font-family: lato;
  height:100%;
  min-height: 954px;
  /* background-color: aqua; */
  /* border:1px solid red */
}
.aside {
  /* border:1px solid grey; */
  /* height:99.8vh; */

  /* background-color: var(----menu-background); */
}
.aside-menu {
  /* border:1px solid black; */
  /* padding:10px; */
  /* box-sizing: border-box; */
  padding: 6px 10px;
  border-radius: 5px;
  /* width: 100%; */
  height: 42px;
  margin: 4px;
  border: 1px solid #e3e3e3;
  background-color: white;
  position: relative;
  margin-bottom: 5px;
}
.nav {
  position: relative;
  left: 0;
  /* border:1px solid white */
}
.home-menu{
  padding:70px
  /* text-align: center; */
}
.logInfo {
  /* border:1px solid white; */
  display: flex;
  font-size: 1.2em;
  /* color:darkgray; */
  height: 30px;
  margin-top: 6px;
  /* width:100px; */
}
.email {
  padding-right: 10px;
  /* width:60px; */
  /* padding-top:5px; */
  /* line-height: 50px; */
}
.logud {
  background: transparent;
  outline: none;
  border: none;
  color: darkgray;
  /* width: 60px; */
  border-left: 2px solid grey;
}

.bm-item {
  /* border:1px solid white; */

  text-decoration: none;
  margin-bottom: 10px;
  color: white;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  left: 0;
  /* left: 36px; */
  /* top: 36px; */
}

.bm-burger-bars {
  /* border:1px solid white; */
  /* background-color: rgb(223, 17, 17); */
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: white;
}

.bm-menu {
  display: flex;
  flex-direction: column;
  /* background: black; */
  /* padding:50px; */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #0d31d1;
}

.bm-item-list {
  /* color: #b8b7ad; */
}

.bm-overlay {
  /* background: rgba(204, 12, 12, 0.3); */
}
.bm-menu-wrap {
  position: absolute;
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100%; */
  top: 50px;
  /* background-color: #373a47; */
}

.dd {
  /* display: flex; */
  flex-direction: column;
}
.menu-item:hover {
  text-decoration: none;
  color: white;
}
.menu-box {
  background-color: #373a47;

  display: none;
}

@media screen and (max-width: 900px) {
  /* .home-container {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
  } */
  .home-menu {
    padding:40px
  }
  .home {
    width: 100%;
    min-height: auto;
    height:calc(100vh - 50px);
    display:flex;
    
    
  }
  .page {
    border:1px solid black
  }
  .aside {
    display: none;
  }
  .aside-menu {
    padding:0;
    display:flex;
    /* border:1px solid black */

    
  }
  .menu-title {
    padding-top:5px;
    padding-left:5px;
  }
  .menu-box {
    /* border:1px solid red; */
    position: absolute;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    top: 50px;
    right: 0px;
    bottom:60px;
    background-color: var(----menu-background);
    
    height: -webkit-fill-available;

    width: 60%;
    padding:5px;
    z-index: 999;
  }
}

@media screen and (min-width:600px)  {
  .menu-box {
    
   width:40%
  }
  }

