.rdp-weeknumber {
    color:lightgrey
}
tr{
    border-width: 0;
}
.rdp-day {
    border:1px solid #eaecec;
    border-radius: 0 !important;
    padding:20px 25px  !important;
    font-size: 18px !important;
    font-weight: 800 !important;
    
}

.rdp-day_today {
   
    border:1px solid var(----primary-color) !important
}
 td button {
    border:1px solid lightgrey

}
.rdp-day_selected {
    background-color: var(----navmenu-background) !important;
    
}

.rdp-head_cell {
    color:#555 !important;
    font-size: 1em !important;
}

.rdp-day_outside {
    color:white !important;
}

.rdp-day_outside:hover {
    background-color: white !important;
}

.rdp_head {
    /* display:none !important; */
}

.Calendar thead {
    display:none !important;
}

.Calendar .slide-down {
    padding-bottom: 10px;
    margin-top: 10px;
}
.calendar-text {
    font-size: 1rem;
    padding: 0 20px;
}
.calendar-text .slide-down{
    min-height: 345px;
}

.DayPicker {
    width: 100%;
    max-width: 474px;
    margin: auto;
    padding: 20px;
    position: relative;
    user-select: none;
    background: white;
    box-shadow: 0 13px 13px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid var(--border-color);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: var(--gray-header);
}
abbr {
    text-decoration: none;
}
.DayPicker:focus {
    outline: none;
}
.DayPicker-Close .close {
    position: absolute;
    right: 16px;
    font-size: 12px;
    padding: 4px;
    color: var(--header-color);
}
.DayPicker-NavBar {
    display: none;
}

.DayPicker-Year-Navigator,
.DayPicker-Month-Navigator {
    width: 100%;
    /* margin: auto; */
    text-align: center;
    /* margin-bottom: 15px; */
    /* border:1px solid red */
}
.DayPicker-Year-Navigator {
    font-size: 14px;
}
.DayPicker-Year-Navigator .year {
    min-width: 40px;
}
.DayPicker-Month-Navigator .month {
    min-width: 100px;
}

.DayPicker-NavButton {
    display: inline-block;
    width: 50px;
    height: 30px;
    padding: 0 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-NavButton--prev {
    left: 5rem;
}
.DayPicker-NavButton--prev::before {
    content: '\00276E';
}
.DayPicker-NavButton--next {
    right: 5rem;
}
.DayPicker-NavButton--next::before {
    content: '\00276F';
}

.DayPicker-Month {
    width: 100%;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;
}
.DayPicker-Month .DayPicker-Caption {
    display: table-caption;
    text-align: center;
    margin-bottom: 15px;
}
.DayPicker-Month .DayPicker-Weekdays {
    display: table-header-group;
}
.DayPicker-Month .DayPicker-WeekdaysRow {
    display: table-row;
}
.DayPicker-Month     .DayPicker-Weekday {
    display: table-cell;
    padding: .5rem;
    font-size: .875em;
    text-align: center;
    color: #555555;
}
.DayPicker-Month  .DayPicker-Body {
    display: table-row-group;
}
.DayPicker-Month .DayPicker-Week {
    display: table-row;
}
.DayPicker-Month  .DayPicker-WeekNumber {
    display: table-cell;
    padding-right: 15px;
    vertical-align: middle;
    font-size: 13px;
    color: #cecece;
}
.DayPicker-Month   .DayPicker-Day {
    display: table-cell;
    padding: .5rem;
    border: 1px solid #eaecec;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
}
.DayPicker-Month   .DayPicker-Day:hover {
    background: rgb(0 161 0 / 20%);
}
.DayPicker-Month   .DayPicker-Day:focus {
    outline: none;
}
.DayPicker-Day--today {
    border: 2px solid green;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #b9b9b9;
    cursor: default;

    
}
.DayPicker-Day--disabled:hover {
    border: 1px solid #eaecec;
    background: rgba(255, 0, 0, 0.2);
}
.DayPicker-Day--outside {
    cursor: default;
    color: transparent;

}
.DayPicker-Day--outside:hover {
    background: none;
}
.DayPicker-Day--sunday {
    background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: white;
    background-color: green;
}
.DayPicker-Footer {
    padding-top: 20px;
}
.DayPicker-Footer   .DayPicker-TodayButton {
    height: 32px;
    font-size: 12px;
    font-weight: bold;
    color: var(--body-color);
    text-transform: uppercase;
    background: none;
    border: none;
    text-decoration: underline;
}


@media screen and  (max-width: 420px) {
    .Calendar {
        font-size: 14px;
    }
}

@media (max-width: 310px) {
    .Calendar {
        font-size: 14px;
    }

    .DayPicker {
        padding: 20px 0 0;
    }
}

@media screen and (max-width:1317px) {
    .Calendar {
        /* border:1px solid red; */
        /* top:12%; */
        margin-top: 160px;
        left:0
    }
}

@media screen and (max-width:500px) {
    .Calendar {
      width:95vw;
      padding-left: 0;
     
    }
   
   .rdp-day {
    padding:15px 22px !important;
    font-size: 16px !important;

   }
    }

    @media screen and (max-width:390px)  {

        .rdp-day {
            padding:10px !important;
           
        }
        

    }