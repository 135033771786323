.inputBox {
    /* border:1px solid red; */
    /* width: 70%; */
    padding:7px 15px;
    width:350px;
    /* paddin:5px; */
    border-radius: 5px;
}
.wrap{
    /* border:2px solid red; */
display: flex;
/* flex-direction: column; */
}
.dateRange {
    font-size: 1.3em;
    padding-right: 15px;
}




@media screen and (max-width:1315px){
.wrap{
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}
.inputBox {
    width:100%
}
/* .inputBox {
    max-width:400px;
} */
}

@media screen and (min-width:400px){
.calendar {
    /* border:1px solid red; */
    margin-left: 235px;
}

}
@media screen and (max-width:900px) {
.inputBox {
    width:100%
}
}
@media screen  and (width:1024px) {

.wrap {
    flex-direction: column;
}
.inputBox {
    width:100%
}
}
@media screen and (width:915px) {
    .wrap {
        flex-direction: column;
    }
    .inputBox {
        width:100%
    }
}
@media screen and (width:912px) {
    .wrap {
        flex-direction: column;
    }
    .inputBox {
        width:100%
    }
}
@media screen and (width:914px) {
    .wrap {
        flex-direction: column;
    }
    .inputBox {
        width:100%
    }
}