.menu{
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
    /* border: 1px solid white; */
    /* height: auto; */
  }
  .menu-item {
    /* color: white; */
    text-decoration: none;
    display: block;
    /* font-size: 1.2em; */
    /* margin-bottom: 10px; */
  }
  .bm-overlay {
    background: rgba(243, 50, 50, 0.3);
  }

  /* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 20px;
  top:5px;
  left:7px;
  /* left: 36px; */
  /* top: 36px; */
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  height:14% !important;
  /* border-radius: 2px; */
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width:60%;
  /* background-color: #b8b7ad !important; */
}

/* General sidebar styles */
.bm-menu {
  background: #0c33e0;
  /* padding: 2.5em 1.5em 0; */
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.7);
  left:0;
  top:50px !important;
  bottom:60px !important;
  height: auto;

}