
.modal-open {
    position:fixed;
    height:100vh;
    top:70px;
    left:42px;
    background-color:rgba(0,0,0,0.2) ;
    z-index:10;
    width:1438px
}


.fourWeeksCalcontainer{
    /* border:1px solid red; */
    margin-top:42px;
    display:flex;
    /* justify-content: space-between; */
}
.weeknumberCont {
    /* border:1px solid blue; */
    margin:0px 28px;
    /* //width:320px */
}
.print-btn {
    width: 95px;
    height: 40px;
    border-radius: 4px;
    background-color: #982626;
    color:white
}
.fourweeksreportdesc {
    color:#46484c;
    font-size: 12px;
    letter-spacing: 0.24px;
    line-height: 35px;
    position: absolute;
    top:35px;
    left:50px;
    /* margin-left:11px */
}
.selectbox-line {
    position: absolute;
    background-color: #982626;
    height:39px;
    width:1px;
    right:11%;
    top:0px
}
.weeknumber_select{
    height:40px;
    width:358px;
    border:1px solid #8d8d8d;
    border-radius: 4px;
    position: relative;
    z-index: 11;
}
.weeknumber_arrow {
    position: absolute;
    top:10px;
    right:10px;
    rotate: 180deg;
    
}
.week_box{
    height:295px;
    margin-top: 36px;
    border-left: 1px solid #8d8d8d;
    z-index:10;
    position: absolute;
    background-color: white;
    border-bottom: 1px solid #8d8d8d;
    border-right: 1px solid #8d8d8d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    width:358px;
   right: 0
}
.chooseWeekText{
    color:#46484c;
    letter-spacing: 0.32px;
    font-weight: bold;
    text-align: center;
    padding:5px 0px
}




/*----------TABLE STYLE--------------*/

.tableTitle {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.32px;
    /* color:#46484c; */
    /* width:150px; */
    border-right:1px solid #dee0e3
}
.smallText {
    font-size: 12px;
    letter-spacing: 0.24px;
    opacity: 0.7;
}
.positioned_row {
   position: relative;
 /* top:0; */
   bottom:-25px
}
.ticket-text {
    border-right: 1px solid #dee0e3;
}
.nobottomborder {
    border-bottom: none;
}

.formatted_body td{
    margin:0;
    padding:0
}
.film_title {
    width:318px;
    /* background-color: #eee !important; */
    border-right:1px solid #dee0e3;
   padding-left:10px;
   font-size: 12px;
   font-weight: bold;
   letter-spacing: 0.24px;
}