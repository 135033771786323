:root {
    color:#000;
    --font-color: #46484c;
    --background-color: #fff;
    --link-color: cornflowerblue;
    --headerBackground: #810101;
    --aside: #ececec;
   --workplanbody:#f7f7f7;
   --workplanborder:#dee0e3;
   --primary-color: #982f2f;
   --oddBack-color: white;
   --placeholderColor:#46484c;
   --date_color:'';
   --dark_background:#f7f7f7;
   --borderColor: #dee0e3;
   --incidentBackColor: #f7f7f7;
   --leftMargin: 4px;
   --lightAndDark:#982f2f;
   --sliderCircle:#fff;
   --opacity: ;
   --textColor:#101010;
   --menusMarginLeft:-660px;
   --arrowHeight: 16px;
   --arrowMargin:-2px;
   --datesColor:#101010;
   --film_title_bg:#eee;
   --all_data_table:#f7f7f7;
   --alt_col_even:#fff;
   --alt_col_odd:rgba(247,247,247,80%)
  }
  
  [data-theme="dark"] {
    --font-color: #fff;
    /* color: #fff; */
    --background-color: #242527;
    --link-color: lightblue;
    --headerBackground:#520000;
    --aside:#1a1b1e;
    --workplanbody: #1a1b1e;
    --workplanborder: #6c6c6c;
    --primary-color:#fff;
   --oddBack-color:#1a1b1e;
   --placeholderColor: #fff;
   --date_color:#fff;
   --dark_background: #242527;
   --borderColor:#8d8d8d;
   --incidentBackColor:#1a1b1e;
   --leftMargin: 24px;
   --lightAndDark:#fff;
   --sliderCircle: #982f2f;
   --opacity:0.6;
   --textColor: #fff;
   --menusMarginLeft:-540px;
   --arrowHeight: 13px;
   --arrowMargin:0px;
   --datesColor:#fff;
   --film_title_bg:#1a1b1e;
   --all_data_table : ''
  }
.header-menu {
  background-color: var(--headerBackground);
}
.left-arrow, .right-arrow {
  height:var(--arrowHeight)
}
.arrows {
  margin-top: var(--arrowMargin);
}
.side-menu , .aside {
  background-color: var(--aside);
}
.opacity{
   opacity: var(--opacity)
}
.text_color{
  color:var(--textColor)
}
.progressDato{
color:var(--datesColor)
}
.workplanBody:nth-child(odd) {
  background-color:  var(--workplanbody);
  border-top:1px solid var(--workplanborder);
  border-bottom: 1px solid var(--workplanborder)
}
.table-striped>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
   background-color: var(--workplanbody);
   color: var(--font-color);
   border-top:1px solid var(--workplanborder);
   /* border-bottom: 1px solid var(--workplanborder) */
 }
 .yealy_data_table > tbody:nth-child(even) {
  background-color: var(--workplanbody);
  color: var(--font-color);
 }
 .film_title  {
  background-color: var(--film_title_bg) !important;
 }
 .borders_color {
  border-right:1px solid var(--workplanborder)
 }
 .all_data_table > tbody:nth-child(even)  {
  /* color: var(--workplanborder) */
  background-color: var(--all_data_table);

 }
 .all_data_table > tbody:nth-child(even) .white_bg {
 background-color: var(--alt_col_even);
 }
 .all_data_table > tbody:nth-child(odd) .white_bg {
  background-color: var(--alt_col_odd);
 }
.antalPeriode {
  color: var(--date_color)
}
.antalBar, .progressBars{
background-color: var(--dark_background);
}
.progressbarsContainer {
  background-color: var(--workplanbody);
  border: 1px solid var(--borderColor)
}
 input[type='radio']:checked:before {
  background: var(--primary-color);
}
::placeholder { /* Edge 12-18 */
  color: var(--placeholderColor);
  /* background-color: #d60d89; */
}
.sent-sms:nth-child(odd) {
  background-color: var(--incidentBackColor);
}
  .containers, .main-menu,  .table , .Calendar,.dagsCalendar, .dagsCalendar2, .config-container, .rapport, .sms-container, .rdp-table  {
    background-color: var(--background-color) ;
    color: var(--font-color) ;
    /* height:100vh; */
    /* color: rgb(31, 30, 30); */

  }
  
  a {
    color: var(--link-color);
  }
  
  /* Custom Dark Mode Toggle Element */
  
  .toggle-theme-wrapper {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    /* text-align: center; */
  }
  
  .toggle-theme-wrapper span {
    font-size: 28px;
    margin: 0 4px;
  }
  
  .toggle-theme {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 40px;
  }
  
  .toggle-theme input {
    display: none;
  }
  
  .slider {
    background-color: var(--lightAndDark);
    position: absolute;
    cursor: pointer;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition: 0.2s;
  }
  
  .slider:before {
    background-color: var(--sliderCircle);
    bottom: 3px;
    content: "";
    height: 14px;
    left: var(--leftMargin);
    position: absolute;
    transition: 0.4s;
    width: 14px;
  }
  
  /* input:checked + .slider:before {
    transform: translateX(26px);
  } */
  
  input:checked + .slider {
    /* background-color: darkgrey; */
  }
  
  .slider.round {
    border-radius: 12px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  