.programContainer {
    border:1px solid gray;
    /* display:grid;
    grid-template-columns: auto 62px; */
    height:1131px;
    -moz-box-sizing: border-box; 
    -webkit-box-sizing: border-box; 
     box-sizing: border-box; 
     color:#46484c
}

.programContainer .rdp-caption {
  margin-bottom: 40px !important;
}
 .startDatoCal thead {
  display:none
}
.endDatoCal  thead {
  display:none
}
.programCalendar thead {
  display:none
}
.programCalendar .weekdays {
  top:80px
}
/* .startDatoCal.rdp-day_selected{
  background-color: #982f2f;
} */
.startDatoCal .weekdays {
  top:95px
}

.endDatoCal .weekdays {
  top:95px
}
.endDatoCal .rdp-day_today {
  border:1px solid #8d8d8d !important
}
.endDatoCal .rdp-day_selected{
  background-color: #8d8d8d !important;
}
#program_table {
  empty-cells: show;
  /* border:1px solid red; */
  position: relative;
  width:1696px;
}

#program_table  > tbody > tr:last-child {border-bottom: 3px solid #dee0e3; }
.saveBtn{
  border-radius:4px;
  background-color: #982f2f;
  color:#fff;
  border:none;
  width:175px;
  letter-spacing: 0.6px;
  height:41px;
  font-size: 12PX;
  font-weight: BOLD;
  float:right;
  font-family: Lato;

}
.daysBtn {
  border-radius:4px;
  background-color: #982f2f;
  color:#fff;
  border:none;
  width:125px;
  letter-spacing: 0.6px;
  height:41px;
  font-size: 12PX;
  font-family: Lato;

  font-weight: BOLD;
  margin-right:12px
}
.weeksBtn {
  border-radius:4px;
  background-color: #fff;
  color:#982f2f;
  border:none;
  width:125px;
  letter-spacing: 0.6px;
  height:41px;
  font-size: 12PX;
  font-weight: BOLD;
  font-family: Lato;

  border:1px solid #982f2f
}
.markAllBtn{
  border:none;
  background: none;
  font-size: 12PX;
  font-weight: BOLD;
  color:#982f2f;
  text-decoration: underline;
  margin-right:10px;
  font-family: Lato;

}
.copyBtn, .editBtn, .deleteBtn{
  border-radius:4px;
  background-color: #fff;
  color:#982f2f;
  border:none;
  width:175px;
  letter-spacing: 0.6px;
  height:41px;
  font-size: 12PX;
  font-weight: BOLD;
  border:1px solid #982f2f;
  margin-left:8px;
  font-family: Lato;
}
input:focus {
  border:none
}
.formattedTD {
    /* position: absolute; */
    background-color: #fff;
    /* z-index: 999; */
    /* width:121px */
}
.formattedTD span {
  background-color: #fff;
  font-weight: 600;
  color:#8d8d8d;
  font-family: Lato;
}
.twelve {
  background-color: #fff;
  font-weight: 600;
  color:#8d8d8d;
  font-family: Lato;
  position: absolute;
  top:16px;
  right:-24px;
  height:27px;
  padding:0px;
  margin:0;
  /* border:1px solid red; */
  z-index: 7;

}
.program_title{
  font-family: lato;
  font-size:25px;
  font-weight: 600;
  color:#000;
  letter-spacing: 0.5px;
}
.programContainer_main {
    padding-top:27px;
    padding-right:50px;
    padding-left:107px;
    position: relative
}


.programContainer_aside {
    background-color: #dee0e3;
    width:62px;
    height:1129px;

}
.salContainer {
  /* border:1px solid ; */
  /* height:220px; */
  position: absolute;
  top:25%;
  left:50px;
 align-items: center;
 line-height: 45px;
 /* display:flex; */
 /* flex-direction: row; */
  /* width: 45px; */
}
.sal_name {
  color:#8d8d8d;
  height:48px;
  width:48px;
  margin:0;
  font-size: 13px;
  overflow: hidden;
  overflow-wrap:break-word;
  /* border:1px solid red */
}
.choose {
    transform: rotate(90deg);
   margin-top: 70px;
   font-family: Lato;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: 0.5px;
  text-align: left;
  color: #101010;
}
.choose_film {
    font-family: Lato;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: 0.5px;
  text-align: left;
  color: #101010;
}
.film {
    transform: rotate(90deg);
    margin-top: 50px;
    font-family: Lato;
   font-size: 25px;
   font-weight: 600;
   font-stretch: normal;
   font-style: normal;
   line-height: 0.8;
   letter-spacing: 0.5px;
   text-align: left;
   color: #101010;
}
.doubleArrows {
    margin-top: 25vh;
    margin-left:18px
}
.chooseFilmContainer{
  border-right:1px solid grey;
  border-bottom:1px solid grey;
  width:400px;
  z-index:9;
  position: absolute;
  right:0;
  top:70px;

  height:1130px;
  background-color: white;
    /* padding:30px; */

}
.rotated {
  transform:rotate(180deg)
}
.arrow_btn{
  border:none;
  background:transparent;
  padding:0;
  margin-top:-10px
}
.search_film{
  border-radius: 10px;
  height:40px;
  width:263px;
  font-family: Lato;
padding-bottom: 5px;
  padding-left:40px;
  border:1px solid #323232
}
.search_film::placeholder {
  color:lightgrey;
  font-family: lato;
  padding-top:-3px
}
.done {
    border:1px solid;
    height:500px;
}
.image_container {
  display:grid;
  grid-template-columns: 1fr 1fr;
  width:278px;
  max-height:933px;
  overflow-x: hidden;
}
.image_container::-webkit-scrollbar {
  width: 5px;
  /* border:1px solid ; */
background-color: #dee0e3;
}
.image_container::-webkit-scrollbar-thumb {
  background: #982f2f; 
  border-right: solid 2px transparent;
  /* box-shadow: inset 1px 1px 1px 100px #757575; */
  
}
.filmContainer {
    display:grid;
    grid-template-columns: 1fr 1fr; 
    flex-wrap: wrap;
    height:400px;
    
}
button {
  border:none;
  background: none;
}
.editDiv {
  border:1px solid #982f2f;
  width:175px;
  background-color: #f1f5f9;
  position: absolute;
  top:39px;
  margin-left:8px;
  height:248px;
  z-index:9;
  padding:10px
}
.copyDiv {
  border:1px solid #982f2f;
  width:175px;
  background-color: #f1f5f9;
  position: absolute;
  top:39px;
  margin-left:8px;
  padding:10px;
  z-index:10;
border-bottom-left-radius: 2px;
border-bottom-right-radius: 2px;
  height:135px
}
.startDatoCal , .endDatoCal {
  border:1px solid #982f2f;
  width:474px;
  height:380px;
  border-radius: 2px;
  background-color: white;
  position: absolute;
  top:85px;
  right:89%;
  z-index:11
}
.programCalendar {
  position: absolute;
  z-index:2;
  background-color: white;
  border:1px solid #982f2f;
  top:90px;
  left:-50%;
  height:347px;
}
.widget{
  /* border:1px solid grey; */
  height:43px;
  width:180px;
  /* margin-top:7px; */
  padding:10px;
  display:none;
  position: absolute;
  top:0px;
  /* left:5px; */
  /* bottom:0; */
  background-color: #f76262;
  border-radius: 5px;
  z-index: 999;
  display:flex;
  /* justify-content: space-between; */
  /* text-align: center; */
  /* align-items: center; */
 }
 .film_name {
  font-family: lato;
  font-weight: 600;
  color:#46484c;
  text-overflow: ellipsis;
  overflow: hidden;
  margin:0;
  white-space: nowrap;
 }
 .film_check {
  /* width:16px; */
  /* height:16px; */
  padding:2px;
  border-radius: 2.7px;
  /* border: 2px solid #656769 !important; */
  margin-top:12px;
  margin-right:10px
 }
 .react-draggable-dragged {
  margin-top: 55px;
 }
.task {
    border: 1px solid white;
    margin: 10px;
   /* // box-shadow: 1px 3px 20px gray; */
    height: 40px;
    /* width:300px; */
    /* border:1px solid grey; */
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
  }
.imgCont {
  margin-bottom: 15px;
  /* margin-right:15px; */
  position: relative;
  text-align: center;
  /* border:1px solid red */
}
/* tooltip style */
.imgCont:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.imgCont .tooltiptext {
  visibility: hidden;
  width: 125px;
  background-color: #982f2f;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 40%;
  left: 6px;
  line-height: 15px;
  /* margin-left: -30px; */
  /* opacity: 0; */
  /* transition: opacity 0.3s; */
  /* text-overflow: ellipsis; */
  /* overflow:hidden; */
  /* white-space: nowrap; */
}

.text_elip {
  text-overflow: ellipsis;
  overflow:hidden;
  white-space: nowrap;
}

/* --------------MODAL STYLE------ */
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}
  .modal-content {
    width: 1180px;
    /* height: 968px; */
    /* background-color: white; */
    /* padding: 25px; */
    border-radius:9px

  }
  
  .modalContainer {
    /* display:grid; */
    /* grid-template-rows: 44px 1100px; */
  }
  .modal_title {
    /* border:1px solid black; */
    margin:0;
    background-color: #dee0e3;

    padding:10px 30px;
    font-size: 18px;
    font-weight: 600;
    color:#46484c;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
  .modal_desc {
    color:#46484c;
    background-color:#f1f5f9;
    border-bottom-left-radius:9px;
    border-bottom-right-radius:9px
    /* border:1px solid red */
  }