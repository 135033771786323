
table {
  color: #46484c
}

.flex {
  /* border:1px solid red; */
  display:flex;
}

.searchInput {
  width: 100%;
/* background-color: #8d8d8d; */
  outline: none;
  border:none;
  padding-top:0;
  padding-left:13px;
  height:40px;
  /* color:#46484c; */
  /* border:1px solid  #dee0e3; */
  border-radius: 5px;
  /* line-height: 2; */
}

.searchDivContainer {
  display:flex;
  justify-content: space-between;
}
.tableOverTitles {
  border-right:1px solid #dee0e3;
  display:grid;
  grid-template-columns: 202px 92px 92px 92px 92px 92px 92px 92px 123px ;
  font-size: 13px;
  color:grey;
  font-style:bold;
}
.table-bordered>:not(caption)>*>*{
  border-left:none
}

.td-width {
  width:95px;
  /* min-width: 95px; */
  /* max-width:95px */
}
tfoot{
  border-top: white !important;
}
.table-bordered>:not(caption)>* {
  /* border-width: 0px; */
}
.theader .ttr > th {
  /* background-color: aqua; */
  height:86px;
  padding:0;
  border-top:none
  /* border:none; */
}
.ticketsDesc {
  width:430px;
  font-size: 13px;
  display:flex;
  color:#8d8d8d;
  font-style: italic;
  justify-content: space-between;
  float: right;
  margin-top: -10px;
}

.table{
  color:inherit;
  /* font-size: 13px; */
}
.striped:nth-child(even){
  background-color: #f7f7f7;
}
.dagens_striped:nth-child(odd) {
  background-color: #f7f7f7;

}
.table>:not(:first-child) {
  border-top: none;
}
.table label span {
  display: none;
}
.table label {
  width: 50%;
  background-color: white;
  /* border-radius: 5px; */
}

.tr {
  text-decoration: underline;
  font-size: 15px;
  padding-top: 5px;
  height: 20px;
}

.rightAlign{
  text-align: right;
  /* border:1px solid; */
  /* height:55px; */
}

.th {
  cursor: pointer;
  color:#8d8d8d;
  text-align: center;
  /* text-decoration: underline; */
  font-weight: normal;
  /* font-size: large; */
}
.th:hover {
  background-color: lightgray;
}
.weeks {
  cursor:none !important;
 
}
.fils {
  border: 1px solid red;
  /* width: 150px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-classes {
    background-color: beige;
}

.trt {
  border:1px solid red;
}
.hide {
  /* border:1px solid red; */

  display:none;
  margin-top: -45px;
}

/* film dag styling */
.filmdag {
  /* border:1px solid red; */
  display:grid;
  border:1px solid white;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto;
}
.films{
  border-right: 1px solid white;
  /* padding:5px; */
/* border:1px solid white; */
}

.weekday{
  padding-top: 10px;
}

@media screen and (min-width:1450px) {
.mobile {
  display: none;
}
}

@media screen and (max-width:1450px){
  .desktop {
    display: none;
  }
 .mobile {
  /* display:flex; */
    width:100%;
   font-size: 13px;

  }
}

@media screen and (max-width:900px){
  .gg {
    /* margin-bottom: 500px; */
    background-color: transparent;
    /* border:1px solid grey */
    /* background-color: aqua; */
  }
  
}
@media print {
  .desktop {
    display:none
  }
  
  .rapport {
    margin:0;
    width:100%;
   
  }
  .mobile {
    width:100%;
    /* border:2px solid red */
  }
  table {
    width:922px
  }

}
@media screen and (max-width:1030px) { 
  .searchDivContainer {
    justify-content: space-evenly;
  }
  .searchInput {
    width:500px
  }
  .updateContainer {
    float:left
    /* border:1px solid  */
  }
}
/* 
@media screen and (max-width: 1439px) and (min-width: 901px) {
  .mobile {
    display: none;
    width:100%;
    font-size:5px
  }
  .desktop {
    display: none;
  }
} */

/* @media screen and (max-width:900px){
  .desktop {
    display: none;
  }
  .mobile {
    width:100%;
   font-size: 13px;

  }
} */