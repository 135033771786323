.dagsCalendar {
  position: absolute;
  top: -10px;
  left: -15px;
  margin-top: 50px;
  letter-spacing: 0.3px;
  /* z-index: 100; */
  border: 1px solid lightgrey;
  width: 450px;
  border-radius: 4px;
  z-index: 999;
  /* background-color: white; */
  box-shadow: 0 13px 13px 0 rgb(0 0 0 / 12%);
}



.dagsCalendar2 {
    position: absolute;
    top: -10px;
    left: -260px;
    margin-top: 50px;
    letter-spacing: 0.3px;
    /* z-index: 100; */
    border: 1px solid lightgrey;
    width: 450px;
  /* background-color: white; */

    border-radius: 4px;
    box-shadow: 0 13px 13px 0 rgb(0 0 0 / 12%);
  z-index: 999;

  }
.rdp-table .rdp_head {
  display: none !important;
}
.dagsCalendar thead {
  display: none;
}
.dagsCalendar2 thead {
    display: none;
  }

  .periode-box {
    display:flex;
  }

  @media screen and (max-width:500px){
.periode-box {
    /* border:1px solid red; */
    flex-direction: column;
}
.dagsCalendar{
    border:1px solid red;
   width: 95vw;
   margin-left: -5px;
}
.dagsCalendar2 {
  left:-20px;
  width: 95vw;

}
.rdp_tbody {
    margin-left: 0 !important
}
  }