 /* @import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;700;900&display=swap');  */

@import url('https://use.typekit.net/axb4nyg.css');



  

body {
  margin: 0;
  font-family: 'lato';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html {
  font-family: 'Lato', sans-serif;

} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.lato-regular {
  font-family: "Lato";
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato";
  font-weight: 700;
  font-style: normal;
}

